<template>
  <div class="p-grid">
    <div class="p-col-12">
      <Card>
        <template #header></template>
        <template #content>
          <div class="p-col-12 p-px-3">
            <Message v-if="isFormSubmitted" severity="success" :sticky="true" :closable="false"
              >Successfully submitted</Message
            >
          </div>

          <div class="p-formgrid p-grid p-fluid p-p-3">
            <LabeledField
              label="Partner Name"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.divisionId.$error || !formData.divisionId?.id"
              :errors="v.divisionId.$errors"
            >
              <AutoComplete
                placeholder="Search by Name, Abbreviation, or Partner ID"
                field="name"
                v-model="formData.division"
                :delay="500"
                :disabled="isFormSubmitted"
                :suggestions="divisions"
                @complete="searchDivisions"
                dropdown
                forceSelection
              />
            </LabeledField>
            <LabeledField label="Teammate (when applicable)" class="p-col-12" :autoLayout="false">
              <AutoComplete
                placeholder="Search by Email, Name, or Employee ID"
                field="name"
                v-model="formData.teammate"
                :delay="500"
                :disabled="isFormSubmitted"
                :suggestions="teammates"
                @complete="searchTeammates"
                dropdown
                forceSelection
              />
            </LabeledField>
            <LabeledField
              label="Level of urgency"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.levelOfUrgency.$error"
              :errors="v.levelOfUrgency.$errors"
            >
              <Dropdown
                v-model="formData.levelOfUrgency"
                :disabled="isFormSubmitted"
                :options="levelOfUrgencyOptions"
              />
            </LabeledField>
            <LabeledField
              label="Detail your issue"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.details.$error"
              :errors="v.details.$errors"
            >
              <Textarea v-model="formData.details" :disabled="isFormSubmitted" :autoResize="true" rows="4" />
            </LabeledField>
            <LabeledField
              label="Links to additional resources"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.resourcesUrl.$error"
              :errors="v.resourcesUrl.$errors"
            >
              <Textarea v-model="formData.resourcesUrl" :disabled="isFormSubmitted" :autoResize="true" rows="4" />
            </LabeledField>
            <LabeledField
              label="Proposed Resolution(s)"
              class="p-col-12"
              :autoLayout="false"
              :show-help="v.resolutions.$error"
              :errors="v.resolutions.$errors"
            >
              <Textarea v-model="formData.resolutions" :disabled="isFormSubmitted" :autoResize="true" rows="4" />
            </LabeledField>
          </div>
        </template>
        <template #footer>
          <div class="p-d-flex p-jc-end p-px-3 p-pb-3">
            <Button :label="isFormSubmitted ? 'Submit Another' : 'Submit'" :loading="loading" @click="handleSubmit" />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import useIntercom from '../../hooks/useIntercom'
import { computed } from '@vue/reactivity'

const store = useStore()
const { fetchFieldOptions, submitHelpEngageEntry } = useIntercom()

const intercomUserId = computed(() => store.state.user?.employeeId)

const formData = reactive({
  division: null,
  teammate: null,
  levelOfUrgency: null,
  details: null,
  resourcesUrl: null,
  resolutions: null,
  divisionId: null,
  teammateId: null
})

const divisions = ref(null)
const teammates = ref(null)
const loading = ref(false)
const isFormSubmitted = ref(false)

const levelOfUrgencyOptions = ['Low', 'Medium', 'High', 'Urgent']

watch(
  () => formData.division,
  (value) => (formData.divisionId = value?.id)
)

watch(
  () => formData.teammate,
  (value) => (formData.teammateId = value?.id)
)

const v = useVuelidate(
  {
    divisionId: { required },
    levelOfUrgency: { required },
    details: { required },
    resourcesUrl: { required },
    resolutions: { required }
  },
  formData
)

const searchDivisions = async (event) => {
  const resp = await fetchFieldOptions('division', event.query)
  divisions.value = resp.data
}

const searchTeammates = async (event) => {
  const resp = await fetchFieldOptions('teammate', event.query)
  teammates.value = resp.data
}

const handleSubmit = async () => {
  if (isFormSubmitted.value) {
    v.value.$reset()
    Object.keys(formData).forEach((key) => (formData[key] = null))
    isFormSubmitted.value = false
    return
  }

  v.value.$touch()
  const data = {
    division_id: formData.divisionId,
    teammate_id: formData.teammateId,
    level_of_urgency: formData.levelOfUrgency,
    issue_details: formData.details,
    resource_urls: formData.resourcesUrl,
    proposed_resolutions: formData.resolutions,
    intercom_user_id: intercomUserId.value
  }

  if (v.value.$error) return
  loading.value = true
  await submitHelpEngageEntry(data)
  loading.value = false
  isFormSubmitted.value = true
}

onMounted(() => window.Intercom('hide'))
</script>

<style scoped></style>
